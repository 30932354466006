import React from "react"
import PropTypes from "prop-types"
import { Typography, Box } from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => {
  return createStyles({
    line: {
      height: "2px",
      backgroundColor: theme.palette.primary.light,
      width: "100%",
      minWidth: theme.spacing(26),
    },
    text: {
      display: "block",
      paddingTop: theme.spacing(1),
      textTransform: "uppercase",
      fontWeight: 600,
      color: theme.palette.primary.main,
      fontSize: "1rem",
      paddingLeft: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(6),
      },
      [theme.breakpoints.up("md")]: {
        paddingLeft: 0,
      },
    },

    container: {
      marginBottom: theme.spacing(6),
      display: "inline-block",
    },
  })
})

const SectionSeparator = ({ title, ...rest }) => {
  const classes = useStyles()
  return (
    <Box classes={{ root: classes.container }} {...rest}>
      <Box classes={{ root: classes.line }} />
      <Typography variant="caption" classes={{ root: classes.text }}>
        {title}
      </Typography>
    </Box>
  )
}

SectionSeparator.propTypes = {
  title: PropTypes.string,
}

export default SectionSeparator
