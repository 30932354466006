import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Icons from "../components/Icons"

import { CTA, CTA_NAMES } from "../components/CTAs"
import { Typography, Grid, Box } from "@material-ui/core"
import { BasicContentBox } from "../components/ContentBoxes"
import {
  FirstSection,
  BoxesSection,
  FocusSection,
} from "../components/Sections"
import { ThreeColumnsContentBox } from "../components/ContentBoxes"
import SectionSeparator from "../components/SectionSeparator"

const INSURANCE_LIST = [
  {
    icon: "Loss",
    text: "Insured against key loss",
  },
  {
    icon: "Theft",
    text: "Insured against external theft",
  },
  {
    icon: "Collusion",
    text: "Insured against internal collusion",
  },
  {
    icon: "LifeCycle",
    text: "Insured across the entire private key lifecycle",
  },
]

const Insurance = () => (
  <Layout>
    <SEO title="Insurance" />
    <FirstSection
      image="Stairs1"
      h1="Insurance"
      boxHeader="1:1 Insurance is possible"
      cta={<CTA name={CTA_NAMES.scheduleDemo} />}
    >
      <Typography variant="body1">
        We did it. Knox has an insurance policy up to the full value of Bitcoin
        holdings held under custody. Collusion, the single most important risk
        in custody, is covered by our insurance policy. Insurance limits are
        exclusively allocated to each client account—not shared.
      </Typography>
    </FirstSection>
    <FocusSection
      title="Transfer the Most Critical Risks"
      paragraphs={[
        "It’s not enough to be insured, we took care to design the right policy. Knox is insured against thefts and losses, transferring all important risks. ",
      ]}
      cta={<CTA name={CTA_NAMES.riskRegistry} />}
    >
      <Box mt={6}>
        <Grid container spacing={4}>
          {INSURANCE_LIST.map((l, i) => {
            const Icon = l.icon ? Icons[l.icon] : null
            return (
              <Grid item xs={6} sm={3} key={`insurance-list-${i}`}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {!!Icon && (
                    <Box width="64px" minWidth="64px" mb={2}>
                      <Icon />
                    </Box>
                  )}
                  <Typography
                    style={{ maxWidth: "150px", minWidth: "120px" }}
                    align="center"
                  >
                    {l.text}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </FocusSection>
    <SectionSeparator title="Insurance features" />
    <BoxesSection
      boxes={[
        {
          content: (
            <BasicContentBox header="Bespoke Insurance Coverage">
              <Typography variant="body1">
                Knox is the insured—we hold the policy. We do not sell
                insurance, but we are insured for what we hold for you, fully
                managed.
              </Typography>
            </BasicContentBox>
          ),
          margin: { xs: { bottom: "150px" } },
          maxWidth: "500px",
        },
        {
          content: (
            <ThreeColumnsContentBox
              boxes={[
                {
                  header: "Pay less",
                  paragraphs: [
                    "Our AUC fees run lower than the insurance premiums most companies would expect to pay.",
                  ],
                  cta: <CTA name={CTA_NAMES.pricing} />,
                },
                {
                  header: "Dial your coverage",
                  paragraphs: [
                    "Whether you choose to target 1:1 coverage or lower, you will be using the same high-quality accounts.",
                  ],
                },
                {
                  header: "Dynamic flexibility",
                  paragraphs: [
                    "Worried about Bitcoin price volatility and your insurance coverage? We take care of this behind the scenes, with the ability to absorb dramatic price swings, while keeping you within your targets.",
                  ],
                },
              ]}
            />
          ),
          margin: { sm: { bottom: "150px" } },
          maxWidth: "850px",
          align: "right",
        },
      ]}
      image={{ name: "Stairs2", position: { md: { right: "150px" } } }}
    />
  </Layout>
)

export default Insurance
